function headerScroll(){
    if($(window).scrollTop()){
        $('header.site-header').addClass('scroll');
    }else{
        $('header.site-header').removeClass('scroll');
    }
}

headerScroll();

$(window).scroll(headerScroll);

$('.open-mobile-menu').click(function(){
    var elem = $(this);

    $('.mobile-menu').toggleClass('show');
})

$('.close-menu').click(function(){
    $('.mobile-menu').removeClass('show');
})

$(document).mouseup(function (e){
    var mobil_menu = $(".mobile-menu, .open-mobile-menu");
    if (!mobil_menu.is(e.target) && mobil_menu.has(e.target).length === 0) {
        $('.mobile-menu').removeClass('show');
    }
});

$(document).on('click', '.fixed-sidebar a', function(){
    $('.mobile-menu').removeClass('show');
});
