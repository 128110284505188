$(document).on('click', '#send_message', function(e){
    var elem = $(this),
        form = elem.parents('form'),
        fields = form.find('input, textarea'),
        success = form.find('.success-screen');

    e.preventDefault();
    fields.each(inputCheck);

    if(form.find('.error').length == 0){
        var results = {
            'user_name' : $.trim($('#name').val()),
            'phone'     : $.trim($('#phone').val()),
            'email'     : $.trim($('#email').val()),
            'message'   : $.trim($('#message').val()),
            'comment'   : '',
            'method'    : 0
        }

        elem.attr('disabled', true);

        sendAjax('add_order', results, function(){
            elem.removeAttr('disabled');
            success.fadeIn(400);
            form.addClass('show-success');
            fields.each(resetInput);
        });
    }
});

$(document).on('click', '.send-vacancy', function(e){
    var elem = $(this),
        form = elem.parents('.send-request'),
        fields = form.find('input'),
        item = elem.parents('.vacancy-item'),
        success = item.find('.success-screen');

    e.preventDefault();
    fields.each(inputCheck);

    if(form.find('.error').length == 0){
        var results = {
            'user_name' : '',
            'phone'     : $.trim(fields.val()),
            'email'     : '',
            'comment'   : item.find('.name').text(),
            'message'   : '',
            'method'    : 1
        }

        elem.attr('disabled', true);

        sendAjax('add_order', results, function(){
            elem.removeAttr('disabled');
            success.fadeIn(400);
            fields.each(resetInput);
            form.stop();
            item.removeClass('show');
            form.fadeOut(300);
        });
    }
});

$(document).on('click', '.close-sc', function(e){
    var elem = $(this),
        popup = elem.parents('.success-screen'),
        form = elem.parents('.show-success');

    e.preventDefault();
    form.removeClass('show-success');
    popup.fadeOut(400);
})