$('.select-day li').click(function(){
    var elem = $(this),
        index = elem.index(),
        siblings = elem.siblings(),
        content = $('.programs-body');

    siblings.removeClass('active');
    content.removeClass('active');
    elem.addClass('active');
    content.eq(index).addClass('active');
})