$(document).on('click', '.show-more', function(){
    var elem = $(this),
        parent = elem.parents('.vacancy-item'),
        dropdown = parent.find('.dropdown'),
        siblings = parent.siblings(),
        dropdowns = siblings.find('.dropdown');

    dropdown.stop();
    dropdown.slideToggle(400);
    dropdowns.stop();
    dropdowns.slideUp(400);
});

$(document).on('click', '.respond-vacancy', function(){
    var elem = $(this),
        parent = elem.parents('.vacancy-item'),
        form = parent.find('.send-request'),
        siblings = parent.siblings(),
        forms = siblings.find('.send-request');

    siblings.removeClass('show');
    forms.stop();
    forms.fadeOut(300);
    
    form.stop();
    form.fadeIn(300);
    parent.addClass('show');
})

$(document).on('click', '.close-vacancy', function(){
    var elem = $(this),
        parent = elem.parents('.vacancy-item'),
        form = parent.find('.send-request');

    form.stop();
    parent.removeClass('show');
    form.fadeOut(300);
})