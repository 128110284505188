function newsSlider(){

    if ($(window).width() < 1200) {
        $('.news-row').owlCarousel({
            loop:true,
            margin: 0,
            items: 1,
            nav:true,
            responsiveClass:true,
            navText: ['<span class="icon-left"></span>','<span class="icon-right"></span>'],
            responsive:{
                767.98:{
                    items: 2
                },
                991.98:{
                    items: 3
                }
            }
        })
    }else{
        $('.news-row').owlCarousel('destroy');
        $('.news-row').removeClass("owl-carousel");
    }

}

newsSlider();

$(window).resize(newsSlider);