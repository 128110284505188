var memberUpdate = 1;

$(document).on('click', '.members .member', function(){
    var elem = $(this),
        index = elem.index(),
        loader = $('.default-block .loading');
    
    if(!elem.hasClass('active') && memberUpdate){
        memberUpdate = 0;
        elem.addClass('active');
        elem.siblings().removeClass('active');

        loader.stop();
        loader.fadeIn(300);

        requestAjax('get_member_info', index, function(res){
            $('.member-info-body').html(res);
            loader.fadeOut(300);
            memberUpdate = 1;
        });
    }
});