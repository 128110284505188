var radio_status = false;

$('.volume-line').slider({
    range: 'min',
    min: 0,
    max: 100,
    value: 100,
    slide: function( event, ui ) {
        var player = document.getElementById("player"),
            value = ui.value,
            volume = value * 0.01;

        if(value == 0){
            $('.music-volume').addClass('mute');
        }else{
            $('.music-volume').removeClass('mute');
        }

        $('.volume-line').slider( "value", value );

        player.volume = volume;

    }
});

function rotatePlayer(){
    var circle = $('.player-circle .image');

    if (circle === void(0)) {
        circle = $({deg: 0});
    }

    return circle.animate(
        {deg: 360},
        {
            duration: 12000,
            easing: 'linear',
            step: function(now){
                circle.css({transform: 'rotate(' + now + 'deg)'});
            },
            complete: function(){
                circle.get(0).deg = 0;
                rotatePlayer();
            },
        }
    );

}

function stopRotatingPlayer(){
    var circle = $('.player-circle .image');

    circle.stop();
    rotatePlayer().stop();
}

function playMusic(){
    var player = document.getElementById("player");

    radio_status = true;
    player.load();
    player.play();
    $('.play-radio').addClass('playing');
    rotatePlayer();
}

function stopMusic(){
    var player = document.getElementById("player");
    
    radio_status = false;
    player.pause();
    $('.play-radio').removeClass('playing');
    stopRotatingPlayer();
}

$(document).on('click', '.play-radio', function(){
    var elem = $(this);

    if(elem.hasClass('playing')){
        stopMusic();
    }else{
        playMusic();
    }

});

setInterval(function(){
    simpleAjax('get_playing', function(res){
        res = JSON.parse(res);

        var name = res.name,
            artist = res.artist,
            id = res.id,
            is_fav = res.is_fav,
            btn = $('.fixed-player .add-to-fav'),
            played = res.played;

        $('.playing-author').html(artist);
        $('.playing-name').html(name);
        $('.playlist-body').html(played);

        if(is_fav){
            btn.addClass('added');
        }else{
            btn.removeClass('added');
        }

        btn.attr('data-id', id);

        if(res.image){
            $('.fixed-player .avatar').text('').css('background-image', 'url(' + res.image + ')');
        }else{
            $('.fixed-player .avatar').text(res.artist.substr(0, 1)).css('background-image', '');
        }

    });
}, 5000);