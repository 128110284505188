function sendAjax(name, data, success, error){
    jQuery.post('/wp-admin/admin-ajax.php', {
        action: name,
        data: data
    },
        function (res) {

            try {
                JSON.parse(res);
            } catch (e) {
                $('.popup').fadeOut(400);
                $('.error-code').html($.trim(res));
                $('.popup.error-popup').fadeIn(400);
                
                return false;
            }

            var json = JSON.parse(res);
            
            if(json.result == 'success'){                        
                success(json);                
            }else if(json.result == 'error' && error){
                error(json);
            }else{
                $('.popup').fadeOut(400);
                $('.error-code').html($.trim(res));
                $('.popup.error-popup').fadeIn(400);                
            }
        }
    );
}

function simpleAjax(name, func){
    jQuery.post('/wp-admin/admin-ajax.php', {
        action: name,
    },
        function (res) {
            func(res); 
        }
    );
}

function requestAjax(name, data, func){
    jQuery.post('/wp-admin/admin-ajax.php', {
        action: name,
        data: data
    },
        function (res) {
            func(res); 
        }
    );
}

function unmaskedPhone(val){
    return val.replace(/[^0-9]/g, '');
}

function pickInputs(elem){

    elem = $(elem);

    var result = {};

    elem.find('input').each(function(){
        var input    = $(this),
            parent   = input.parents('.form-group'),
            id       = input.attr('id'),
            type     = 'none',
            val      = $.trim(input.val()),
            required = input.hasClass('required'),
            name     = input.attr('name');
        parent.removeClass('error');
        parent.find('.error-message').remove();

        type = input.data('type');

        if(!type || type == 'undefined'){
            type = 'text';
        }

        if( type == "phone" ){
            val = val.replace(/[^0-9]/g, '');
        }

        if( type == "checkbox" ){
            if(input.prop("checked")){
                val = 1;
            }else{
                val = 0;
            }            
        }

        result[name] = [val, id, type, required];

    });

    return result;

}